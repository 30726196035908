<template>
  <div style="padding-top: 1rem">
    <div class="reports_filter px-5 mb-8">
      <v-form ref="form" class="d-flex align-center">
        <span v-if="!isLoading">
          <small>Jornada: </small>
          <strong v-if="!isLoading">{{ currentJourney?.journey?.name || "" }}</strong>
        </span>
        <v-row dense justify="end">
          <v-col v-if="selectedInterval === 6" lg="3" md="5" sm="12" xs="12">
            <div style="height: 40px" class="d-flex align-center">
              <DatePicker
                v-model="pickerRange"
                range
                value-type="YYYY-MM-DD"
                format="DD/MM/YYYY"
                style="width: 100% !important"
                @change="setValuePickerToFilter($event)"
              />
            </div>
          </v-col>
          <!-- <v-col v-if="selectedInterval === 6" cols="2">
              <z-input-date
                v-model="filter.startsAt"
                class="mr-1"
                label="De:"
                format="pt-BR"
                type="date"
                :hideDetails="true"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </v-col>
  
            <v-col v-if="selectedInterval === 6" cols="2">
              <z-input-date
                v-model="filter.endsAt"
                class="mr-1"
                label="Até:"
                format="pt-BR"
                type="date"
                :min="minimumDate"
                :hideDetails="true"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </v-col> -->

          <v-col :cols="selectedInterval < 6 ? 3 : 3">
            <z-select
              class="intervalo_relatorio"
              style="max-height: 240px !important"
              v-model="selectedInterval"
              :hideDetails="true"
              label="Selecione o intervalo"
              itemValue="value"
              :items="intervalFilters"
              itemText="name"
            >
              <template #item="{ item }">
                <section
                  class="d-flex align-center justify-space-between"
                  style="width: 100%"
                >
                  <strong> {{ item.name }} </strong>
                  <small> {{ item.textDate }} </small>
                </section>
              </template>
            </z-select>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div v-if="!isLoading" class="wrapper__card mx-5">
      <div class="wrapper__card__body">
        <v-row class="pl-3">
          <h2 style="font-size: 1.25rem !important">
            Métricas disponíveis apenas para E-mail
          </h2>
        </v-row>

        <v-row dense justify="space-around">
          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div class="icon_card_report" style="background-color: rgb(246, 189, 2)">
                  <v-icon class="ml-3 mt-3" color="white">$send</v-icon>
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700">ENVIADOS</span>
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: rgb(246, 189, 2)">{{ totalEmailSent }} </span>
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div
                  class="icon_card_report"
                  style="background-color: rgb(105, 188, 203)"
                >
                  <v-icon class="ml-3 mt-3" color="white">mdi-email-open-outline</v-icon>
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700">ABERTURAS</span>
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: rgb(105, 188, 203)">
                      {{ totalOpens }} ({{
                        totalEmailSent !== 0
                          ? ((totalOpens / totalEmailSent) * 100).toFixed(2)
                          : 0
                      }}%)
                    </span>
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div
                  class="icon_card_report"
                  style="background-color: rgba(73, 203, 140, 1)"
                >
                  <v-icon class="ml-3 mt-3" color="white">$arrow_back</v-icon>
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700"
                      >TOTAL CLIQUES</span
                    >
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: rgba(73, 203, 140, 1)"
                      >{{ totalEmailClicks }} ({{
                        totalEmailSent !== 0
                          ? ((totalEmailClicks / totalEmailSent) * 100).toFixed(2)
                          : 0
                      }}%)</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div class="icon_card_report" style="background-color: rgb(100, 45, 108)">
                  <v-icon class="ml-3 mt-3" color="white">mdi-email-sync-outline</v-icon>
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700">BOUNCES</span>
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: rgb(100, 45, 108)"
                      >{{ totalBounced }} ({{
                        totalEmailSent !== 0
                          ? ((totalBounced / totalEmailSent) * 100).toFixed(2)
                          : 0
                      }}%)</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div class="icon_card_report" style="background-color: #ffd2a9">
                  <v-icon class="ml-3 mt-3" color="white"
                    >mdi-alert-octagon-outline</v-icon
                  >
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700">SPAM</span>
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: #ffd2a9"
                      >{{ totalSpamed }} ({{
                        totalEmailSent !== 0
                          ? ((totalSpamed / totalEmailSent) * 100).toFixed(2)
                          : 0
                      }}%)</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div class="icon_card_report" style="background-color: #e7a2cc">
                  <v-icon class="ml-3 mt-3" color="white">$unsubscribe</v-icon>
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700">OPTOUT</span>
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: #e7a2cc"
                      >{{ totalOptOuts }} ({{
                        totalEmailSent !== 0
                          ? ((totalOptOuts / totalEmailSent) * 100).toFixed(2)
                          : 0
                      }}%)</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div class="icon_card_report" style="background-color: #004c91">
                  <v-icon class="ml-3 mt-3" color="white">$reply</v-icon>
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700">REPLYS</span>
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: #004c91"
                      >{{ totalSpamed }} ({{
                        totalEmailSent !== 0
                          ? ((totalSpamed / totalEmailSent) * 100).toFixed(2)
                          : 0
                      }}%)</span
                    >
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <v-row justify="center">
                <div class="icon_card_report" style="background-color: rgb(222, 87, 66)">
                  <v-icon class="ml-3 mt-3" color="white">$groups</v-icon>
                </div>
                <div class="ml-5">
                  <v-row class="mb-1">
                    <span style="font-size: 0.85rem; font-weight: 700">AUDIÊNCIAS</span>
                  </v-row>
                  <v-row class="mt-0">
                    <span style="color: rgb(222, 87, 66)">{{
                      audienceUniqueIdsCounter
                    }}</span>
                    <!-- <span style="color: rgb(222, 87, 66)"
                        >{{ totalSpamed }} ({{
                          totalEmailSent !== 0
                            ? ((totalSpamed / totalEmailSent) * 100).toFixed(2)
                            : 0
                        }}%)</span
                      > -->
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mb-6" cols="12">
            <v-card elevation="2" height="420" shaped class="mx-3 py-2 px-5">
              <v-row class="ml-2 mt-2 d-flex align-center justify-space-between">
                <span style="font-size: 0.8rem; font-weight: 600">JORNADA</span>

                <span>
                  <small class="mr-2"
                    >Início: <strong>{{ startDateComputed || "s.d" }}</strong>
                  </small>
                  <small
                    >Fim: <strong>{{ endDateComputed || "s.d" }}</strong></small
                  >

                  <template v-if="clickedsNodes.length">
                    <v-icon class="ml-5" color="#004C91" @click="resetFilterByJourney"
                      >$settings_backup_restore</v-icon
                    >
                    <span class="ml-2">|</span>
                    <v-icon class="ml-2" color="#004C91">$filter_alt</v-icon>
                  </template>
                </span>
                <!-- <span
                    v-if="currentClickedNode"
                    class="d-flex align-center justify-center"
                  >
                    <small class="mr-3">filtrando step:</small>
  
                    <strong style="font-size: 0.95rem">
                      {{ currentClickedNode.label }}</strong
                    >
  
                    <v-icon class="ml-3" color="#004C91" @click="resetFilterByJourney"
                      >$settings_backup_restore</v-icon
                    >
                  </span> -->
              </v-row>
              <div class="tree-component">
                <TreeComponent
                  :key="keyComponent"
                  :database-config-id-prop="databaseConfigId"
                  :nodes="tree"
                  @node_click="treeNodeClicked"
                />
              </div>
            </v-card>
          </v-col>

          <v-col cols="8">
            <v-card elevation="2" height="420" shaped class="mx-3 py-2 px-5">
              <v-row class="ml-2 mt-2 d-flex align-center justify-space-between">
                <span style="font-size: 0.8rem; font-weight: 600">BOUNCES/CLIQUE</span>
                <!-- <v-icon v-if="filter.channel" color="#004C91" @click="doughnutChartCancel"
                    >$settings_backup_restore</v-icon
                  > -->
              </v-row>
              <section>
                <BarChart
                  v-if="!bouncesAndClicksChartDataLoading"
                  :chart-data="bouncesAndClicksChartDataInformation"
                  :chart-options="bouncesAndClicksChartOptions"
                />
                <aside
                  v-else
                  class="d-flex align-center justify-center"
                  style="height: 320px"
                >
                  <z-loading />
                </aside>
              </section>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="2" height="420" shaped class="mx-3 py-2 px-5">
              <v-row class="ml-2 mt-2 d-flex align-center justify-space-between">
                <span style="font-size: 0.8rem; font-weight: 600">CANAL</span>
                <v-icon v-if="filter.channel" color="#004C91" @click="doughnutChartCancel"
                  >$settings_backup_restore</v-icon
                >
              </v-row>
              <DoughnutChart
                v-if="totalByChannel"
                :labels="['Email', 'SMS']"
                :totals="totalByChannel"
                :chart-info-prop="donutChartData"
              />
              <!-- @click="doughnutChartClicked" -->
            </v-card>
          </v-col>
        </v-row>

        <!-- VERSÃO 1 DO MODAL DE FILTRO DA TABELA -->
        <v-row>
          <!-- <v-col cols="4">
              <v-card elevation="2" flat shaped class="mx-3 px-5 py-3 mb-5">
                <v-row>
                  <v-col cols="12">
                    <v-menu offset-y :close-on-content-click="closeOnContentClick">
                      <template v-slot:activator="{ on, attrs }">
                        <z-input
                          v-model="orderMessage"
                          label="Ordenar por:"
                          :hideDetails="true"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </z-input>
                      </template>
  
                      <v-row>
                        <v-col cols="12">
                          <v-card flat class="px-3 py-3">
                            <v-row dense class="d-flex flex-column">
                              <v-col cols="12">
                                <z-select
                                  v-model="tableName"
                                  :hideDetails="true"
                                  label="Coluna da tabela"
                                  return-object
                                  :items="headersFilter"
                                  itemText="text"
                                />
                              </v-col>
                              <v-col cols="12">
                                <z-select
                                  v-model="orderName"
                                  :hideDetails="true"
                                  label="Ordem"
                                  return-object
                                  :items="order"
                                  itemText="text"
                                />
                              </v-col>
                              <v-col cols="12" class="d-flex align-center justify-center">
                                <z-btn
                                  text="Aplicar"
                                  style=""
                                  primary
                                  @click="orderByModal"
                                />
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card elevation="2" flat shaped class="mx-3 px-5 py-3 mb-5">
                <v-row>
                  
                  <z-select
                  style="max-width: 300px !important;"
                  v-if="journeys"
                  hide-details
                  v-model="filter.selectedJourney"
                  placeholder="Filtrar por jornada"
                  label="Filtrar por jornada"
                  :items="journeys"
                  itemText="name"
                  itemValue="id"
                  class="select"
                  />
                  <v-icon @click="filter.selectedJourney = null" class="ml-2">mdi-close</v-icon>
                </v-row>
              </v-card>
            </v-col>
  
             -->

          <v-col cols="12">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <div class="px-5 py-4 d-flex justify-end align-center">
                <z-btn
                  small
                  rounded
                  primary
                  text=""
                  :icon="`mdi-cloud-download`"
                  :is-loading="loadingDownload"
                  @click="downloadDocument"
                />
              </div>
              <!-- <div class="px-5 py-2 d-flex justify-end" style="">
                  <z-btn
                    small
                    rounded
                    primary
                    text=""
                    :icon="`mdi-cloud-download`"
                    :is-loading="loadingDownload"
                    @click="downloadDocument"
                  />
                </div> -->
              <z-pagination
                auto-pagination
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="10"
                :number-of-items="usersList.length"
                :items="usersList"
              >
                <template #data="{ paginatedItems }">
                  <div
                    class="table-wrapper"
                    style="height: 550px !important; max-height: 550px !important"
                  >
                    <z-table
                      class="table"
                      :headers="headers"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      :is-loading="loadingSortTable"
                      @sort="getSortedJourneysReportsByCompanyId($event)"
                    >
                      <template #no-data>
                        <div class="no-data-table d-flex align-end justify-center">
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>

                      <template v-slot:[`item.created`]="{ item }">
                        <div>
                          <span>
                            {{ formatDate(item.created) }}
                          </span>
                        </div>
                      </template>

                      <template v-slot:[`item.journeyStepId`]="{ item }">
                        <div>
                          <span>
                            {{ getStepIdName(item.journeyStepId) }}
                          </span>
                        </div>
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <div class="wrapper__card">
        <v-row justify="center">
          <z-loading />
        </v-row>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable no-undef -->
<script>
import { findNodeById } from "./utils";
import { computed } from "vue";
import { usersToDetailMock, journeyMock } from "./mock";
import ApiClient from "@/services/ApiClient";
import TreeComponent from "./components/fluxogram/ExampleList.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
// import LineChart from "./components/Line.vue";
import BarChart from "./components/Bar.vue";
import DoughnutChart from "./components/Doughnut.vue";
export default {
  components: {
    // LineChart,
    BarChart,
    DoughnutChart,
    TreeComponent,
    DatePicker,
  },
  data() {
    return {
      audienceUniqueIdsCounter: 0,
      clickedsNodes: [],
      bouncesAndClicksChartDataLoading: false,
      bouncesAndClicksChartDataInformation: {},
      bouncesAndClicksChartDataInformationMock: {
        labels: [
          "out/2022",
          "nov/2022",
          "dez/2022",
          "jan/2023",
          "fev/2023",
          "mar/2023",
          "abr/2023",
          "mai/2023",
          "jun/2023",
          "jul/2023",
          "ago/2023",
          "set/2023",
          "out/2023",
        ],
        datasets: [
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 20.63, 21.9],
            label: "Bounces",
            backgroundColor: "#B2D2D6",
          },
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.46, 0],
            label: "Cliques",
            backgroundColor: "#00BBD3",
          },
        ],
      },
      bouncesAndClicksChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        //  onClick: (e) => this.getChartClickedInfo(e.chart, e, "send"),
        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
          },
        },
      },
      pickerRange: "",
      usersList: [],
      donutChartData: null,
      currentClickedNode: null,
      templatesEmailList: [],
      audiencesList: [],
      templatesSMSList: [],
      databaseConfigId: null,
      keyComponent: 0,
      tree: null,
      isTreeLoading: false,
      currentJourney: null,
      journey: null,
      usersToDetailMock,
      journeyMock,
      isFilteringGraph: false,
      isFilteringHour: false,
      loadingSortTable: false,
      orderMessage: "",
      tableName: "",
      orderName: "",
      closeOnContentClick: false,
      sortDesc: false,
      currentTableUrl: null,
      sendChartDataInformationArr: [],
      engageChartDataInformationArr: [],
      sendChartDataInformation: null,
      sendChartDataInformationLoading: false,
      engageChartDataInformationLoading: false,
      engageChartDataInformation: null,
      sendChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e) => this.getChartClickedInfo(e.chart, e, "send"),
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
          },
        },
      },

      engageChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e) => this.getChartClickedInfo(e.chart, e, "engage"),

        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
            ticks: {
              callback: function (value) {
                return value;
              },
            },
          },
        },

        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let A = `${context.parsed.y}`;
                let B = A.replace(".", ",");
                let label = `${context.dataset.label} ${B}%` || "";
                return label;
              },
            },
          },
        },
      },
      actualTab: "",
      tabs: [
        { id: "send", text: "Quantidade de envios" },
        { id: "engage", text: "Engajamento" },
      ],
      selectedInterval: 1,
      loadingDownload: false,
      http: new ApiClient(),
      isLoading: true,
      totalSent: null,
      totalEmailSent: null,
      totalSMSSent: null,
      totalSpamed: null,
      totalBounced: null,
      totalOptOuts: null,
      totalOpens: null,
      totalClicks: null,
      totalEmailClicks: null,
      totalSMSClicks: null,
      totalByChannel: null,
      openRateByDateRange: null,
      clickRateByDateRange: null,
      clicksData: null,
      journeys: null,
      opensData: null,
      journeyFilterSelected: null,
      temporalFilters: [
        { name: "Pontual", value: "punctual", id: 0 },
        { name: "Recorrente", value: "recurring", id: 1 },
      ],
      intervalFilters: [
        {
          name: "Personalizado",
          value: 6,
        },
        {
          name: "Últimos 12 meses",
          value: 1,
          textDate: this.getLastTwelveMonthsUltilDate(),
        },
        {
          name: "Ano até à data",
          value: 2,
          textDate: this.getYearUntilDate(),
        },
        {
          name: "Último ano",
          value: 3,
          textDate: this.getAllLastYear(),
        },
        {
          name: "Mês até à data",
          value: 4,
          textDate: this.getMonthUntilToday(),
        },
        {
          name: "Último mês",
          value: 5,
          textDate: this.getLastMonth(),
        },
      ],
      headersTemplates: [
        { text: "Template", value: "name", sortable: true },
        { text: "Data último envio", value: "lastSend", sortable: true },
        { text: "Status", value: "status" },
        { text: "Tipo", value: "templateType" },
        { text: "Abertos", value: "opened" },
        { text: "Cliques", value: "clicked" },
      ],
      // headers: [
      //   { text: "Campanha", value: "name", sortable: true },
      //   { text: "Data de criação", value: "dateCreated", sortable: true },
      //   { text: "Data último envio", value: "lastSend", sortable: true },
      //   { text: "Status", value: "status" },
      //   { text: "Tipo", value: "journeyType" },
      //   { text: "Próxima atualização", value: "nextUpdate" },
      //   { text: "Abertos", value: "opened" },
      //   { text: "Cliques", value: "clicked" },
      // ],
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "E-mail", value: "email", sortable: true },
        { text: "Celular", value: "phone", sortable: true },
        { text: "Data de criação", value: "created", sortable: true },
        // { text: "Step", value: "journeyStepId", sortable: true },
      ],
      headersFilter: [
        { text: "Campanha", value: "name", sortable: true },
        { text: "Data de criação", value: "dateCreated", sortable: true },
        { text: "Data último envio", value: "lastSend", sortable: true },
      ],
      order: [
        { text: "Crescente", value: "ASC" },
        { text: "Decrescente", value: "DESC" },
      ],
      templates: [],
      paginatedItems: [],
      paginatedTemplatesItems: [],
      filtered: [],
      recent: [],
      filter: {
        stepId: null,
        startsAt: null,
        selectedJourney: this.$route.params.id,
        endsAt: null,
        groupBy: "month",
      },
      currentClickedNodeId: null,
    };
  },
  provide() {
    return {
      databaseConfigId: computed(() => this.databaseConfigId),
      currentClickedNodeId: computed(() => this.currentClickedNodeId),
      clickedNodesList: computed(() => this.clickedsNodes),
    };
  },
  async created() {
    await this.mountFilters();
    await this.callAllMethodsFromReport();
  },
  computed: {
    hasAnyFilter() {
      return this.selectedInterval !== 1;
    },
    minimumDate() {
      let MIN = "";

      if (this.filter.startsAt) MIN = this.filter.startsAt;
      else MIN = moment(moment.now()).format("YYYY-MM-DD");

      return MIN;
    },
    startDateComputed() {
      let start = "";
      // let position = this.selectedInterval > 5 ? this.selectedInterval - 1 : this.selectedInterval;
      // const choosed = this.intervalFilters[position];
      const choosed = this.intervalFilters.find((d) => d.value == this.selectedInterval);
      if (choosed?.textDate)
        start = choosed?.textDate?.split("até")[0].replaceAll("-", "/") || "";
      else start = this.formatDate2(this.filter.startsAt);
      return start;
    },
    endDateComputed() {
      let end = "";
      // let position = this.selectedInterval > 5 ? this.selectedInterval - 1 : this.selectedInterval;
      // const choosed = this.intervalFilters[position];
      const choosed = this.intervalFilters.find((d) => d.value == this.selectedInterval);
      if (choosed?.textDate)
        end = choosed?.textDate?.split("até")[1].replaceAll("-", "/") || "";
      else end = this.formatDate2(this.filter.endsAt);

      return end;
    },
  },
  watch: {
    tableName(nv) {
      if (nv && this.orderName) {
        this.orderMessage = `${nv.text}/${this.orderName.text}`;
      }
    },
    orderName(nv) {
      if (nv && this.tableName) {
        this.orderMessage = `${this.tableName.text}/${nv.text}`;
      }
    },
    async selectedInterval(nv) {
      if (nv) {
        if (nv !== 6) this.pickerRange = "";
        await this.mountFilters();
        this.filterReports();
      }
    },
    "filter.endsAt"(nv) {
      if (
        nv &&
        this.filter.startsAt &&
        this.selectedInterval == 6 &&
        !this.isFilteringGraph
      ) {
        const start = moment(this.filter.startsAt, "YYYY-MM-DD");
        const end = moment(nv, "YYYY-MM-DD");
        const diference = end.diff(start, "days");

        if (diference <= 30) this.filter.groupBy = "day";
        else this.filter.groupBy = "month";

        this.filterReports();
      }
    },
    "filter.selectedJourney"() {
      this.filterReports();
    },
    "filter.startsAt"(nv) {
      if (
        nv &&
        this.filter.endsAt &&
        this.selectedInterval == 6 &&
        !this.isFilteringGraph
      ) {
        const start = moment(nv, "YYYY-MM-DD");
        const end = moment(this.filter.endsAt, "YYYY-MM-DD");
        const diference = end.diff(start, "days");

        if (diference <= 30) this.filter.groupBy = "day";
        else this.filter.groupBy = "month";

        this.filterReports();
      }
    },
  },
  methods: {
    setValuePickerToFilter(evt) {
      this.filter.startsAt = evt[0];
      this.filter.endsAt = evt[evt.length - 1];
    },
    // getStepIdName(id) {
    //   let NAME = ''

    //   const NODE = this.currentJourney.steps.find(el => el.id == id)
    //   console.log(NODE.label, NODE.stepLabel);

    //   NODE.stepLabel ? NAME = NODE.stepLabel : NAME = NODE.labem

    //   return NAME
    // },
    resetFilterByJourney() {
      this.currentClickedNodeId = null;
      this.filter.stepId = null;
      this.currentClickedNode = null;
      this.clickedsNodes = [];
      this.callAllMethodsFromReport();
    },
    async treeNodeClicked(info) {
      const NODE = await findNodeById(this.tree, info);
      if (NODE && NODE.type != "final") {
        this.currentClickedNodeId = info;
        this.currentClickedNode = NODE;
        // como vai ser o filtro? visto qu ea pessoa vai poder clicar em vários nós
        this.filter.stepId = info;

        if (!this.clickedsNodes.find((d) => d.id == NODE.id))
          this.clickedsNodes.push(NODE);
        else {
          const IDX = this.clickedsNodes.findIndex((el) => el.id == NODE.id);
          if (IDX >= 0) this.clickedsNodes.splice(IDX, 1);
        }

        // this.callAllMethodsFromReport();
      }
    },
    drillUpGraph(type) {
      const _ = require("lodash");

      if (type == "send") {
        let INDEX;

        for (let idx = 0; idx < this.sendChartDataInformationArr.length; idx++) {
          if (
            _.isEqual(
              this.sendChartDataInformationArr[idx],
              this.sendChartDataInformation
            )
          ) {
            INDEX = idx;
          }
        }

        if (INDEX - 1 >= 0) {
          this.sendChartDataInformation = this.sendChartDataInformationArr[INDEX - 1];
          this.sendChartDataInformationArr.splice(INDEX);
        }
      }
      if (type == "engage") {
        let INDEX;

        for (let idx = 0; idx < this.engageChartDataInformationArr.length; idx++) {
          if (
            _.isEqual(
              this.engageChartDataInformationArr[idx],
              this.engageChartDataInformation
            )
          ) {
            INDEX = idx;
          }
        }

        if (INDEX - 1 >= 0) {
          this.engageChartDataInformation = this.engageChartDataInformationArr[INDEX - 1];
          this.engageChartDataInformationArr.splice(INDEX);
        }
      }
    },
    async getChartClickedInfo(chart, e, type) {
      this.isFilteringGraph = true;
      const points = chart.getElementsAtEventForMode(
        e,
        "nearest",
        {
          intersect: true,
        },
        true
      );
      if (points.length) {
        //  const dataset = points[0].datasetIndex;
        // const CURRENT_CLICKED_VALUE = chart.data.datasets[dataset].data[index];
        const index = points[0].index;
        const CURRENT_CLICKED_LABEL = chart.data.labels[index];
        const LABELS_INFO = CURRENT_CLICKED_LABEL.split("/");

        const MONTHS = [
          "jan",
          "fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez",
        ];

        // ------- MÊS >> PARA >> DIA -------------------------
        if (MONTHS.includes(LABELS_INFO[0])) {
          const START_MONTH = moment(CURRENT_CLICKED_LABEL, "MMM/YYYY")
            .startOf("month")
            .format("YYYY-MM-DD");
          const END_MONTH = moment(CURRENT_CLICKED_LABEL, "MMM/YYYY")
            .endOf("month")
            .format("YYYY-MM-DD");

          // TROCAR O FILTRO PARA CORRESPONDER COM DAY OU MONTH
          // TROCAR O FILTRO PARA PEGAR O START E O END ATUAL DO ELEMENTO CLICADO...(PRIMEIRO DIA DO MÊS/ULTIMO DIA DO MÊS OU DATA ATUAL)
          this.filter.groupBy = "day";
          this.filter.startsAt = START_MONTH;
          this.filter.endsAt = END_MONTH;

          if (type == "send")
            await this.monthToDaySendChart(START_MONTH, END_MONTH, "day");

          if (type == "engage")
            await this.monthToDayEngageChart(START_MONTH, END_MONTH, "day");
        }

        // ------- DIA >>>> HORA -------------------------
        if (+LABELS_INFO[0] >= 0 && !CURRENT_CLICKED_LABEL.includes(":")) {
          this.isFilteringHour = true;
          const t = `${LABELS_INFO[2]}-${LABELS_INFO[1]}-${LABELS_INFO[0]}`;
          const START = moment(t).format("YYYY-MM-DD");
          const END = moment(t).add(1, "day").format("YYYY-MM-DD");

          if (type == "send") await this.monthToDaySendChart(START, END, "hour");
          if (type == "engage") await this.monthToDayEngageChart(START, END, "hour");

          this.isFilteringHour = false;
        }
      }

      this.isFilteringGraph = false;

      return;

      // chart.config.data = mock;
      // chart.update();
    },
    doughnutChartCancel() {
      this.filter.channel = "";
      this.callAllMethodsFromReport();
    },

    doughnutChartClicked(info) {
      // INSERIR CHANNEL NO FILTRO E CHAMAR TODAS AS REQUISIÇÕES DAS TELAS NOVAMENTE
      this.filter.channel = info;
      this.callAllMethodsFromReport();
    },

    async monthToDaySendChart(start, end, type) {
      this.sendChartDataInformationLoading = true;
      const INFO = await this.getSentQuantityToChart(start, end, type);

      if (INFO) {
        this.sendChartDataInformation = INFO;
        this.sendChartDataInformationArr.push(INFO);
      }
      this.sendChartDataInformationLoading = false;

      return;
    },

    async monthToDayEngageChart(start, end, type) {
      this.engageChartDataInformationLoading = true;

      const INFO = await this.getEngageQuantityToChart(start, end, type);

      if (INFO) {
        this.engageChartDataInformation = INFO;
        this.engageChartDataInformationArr.push(INFO);
      }

      this.engageChartDataInformationLoading = false;

      return;
    },

    async downloadDocument() {
      if (this.usersList.length) {
        this.loadingDownload = true;
        const WB = XLSX.utils.book_new();
        const WS = XLSX.utils.json_to_sheet(this.usersList);
        XLSX.utils.book_append_sheet(WB, WS, "Dados");
        XLSX.writeFile(WB, "dados.xlsx");
        this.loadingDownload = false;
      }
    },

    mountLabelsMonth(start, end) {
      moment.locale("pt-br");
      const DATES_ARR = [];
      // const t = start.diff(end, 'months')
      // const a = moment(start).format('DD-MM-YYYY')
      // const b = moment(end).format('DD-MM-YYYY')

      if (!end.isSameOrBefore(start)) {
        while (start.isSameOrBefore(end)) {
          DATES_ARR.push(start.format("MMM/YYYY"));
          start.add(1, "month");
        }
      }

      return DATES_ARR;
    },

    mountLabelsDays(start, end) {
      moment.locale("pt-br");
      const DATES_ARR = [];

      if (!end.isSameOrBefore(start)) {
        while (start.isSameOrBefore(end)) {
          // DATES_ARR.push(start.format("DD/MMM"));
          DATES_ARR.push(start.format("DD/MM/YYYY"));
          start.add(1, "day");
        }
      }

      return DATES_ARR;
    },

    mountLabelsHour() {
      const DATES_ARR = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ];

      return DATES_ARR;
    },

    async resetFilters() {
      this.selectedInterval = 1;
      await this.mountFilters();
      this.callAllMethodsFromReport();
    },

    mountFilters() {
      let DATES = null;

      switch (this.selectedInterval) {
        case 1:
          DATES = this.getLastTwelveMonthsUltilDate().split("até");
          this.filter.groupBy = "month";
          break;
        case 2:
          DATES = this.getYearUntilDate().split("até");
          this.filter.groupBy = "month";
          break;
        case 3:
          DATES = this.getAllLastYear().split("até");
          this.filter.groupBy = "month";
          break;
        case 4:
          DATES = this.getMonthUntilToday().split("até");
          this.filter.groupBy = "day";
          break;
        case 5:
          DATES = this.getLastMonth().split("até");
          this.filter.groupBy = "day";
          break;
      }

      if (this.selectedInterval < 6) {
        const START = DATES[0].trim().split("-");
        const END = DATES[1].trim().split("-");

        this.filter.startsAt = `${START[2]}-${START[1]}-${START[0]}`;
        this.filter.endsAt = `${END[2]}-${END[1]}-${END[0]}`;
      } else {
        this.filter.startsAt = "";
        this.filter.endsAt = "";
      }
      return;
    },

    actualizeTab(tab) {
      this.actualTab = tab;
    },

    getAllLastYear() {
      const START_YEAR_DATE = moment()
        .subtract(1, "year")
        .startOf("year")
        .format("DD-MM-YYYY");

      const END_YEAR_DATE = moment(START_YEAR_DATE).endOf("year").format("DD-MM-YYYY");
      const S = `${START_YEAR_DATE} até ${END_YEAR_DATE}`;

      return S;
    },

    getYearUntilDate() {
      const START_YEAR_DATE = moment().startOf("year").format("DD-MM-YYYY");
      const ACTUAL_DATE = moment().format("DD-MM-YYYY");
      const S = `${START_YEAR_DATE} até ${ACTUAL_DATE}`;
      return S;
    },

    getLastTwelveMonthsUltilDate() {
      const START = moment().subtract(12, "months").format("DD-MM-YYYY");
      const END = moment().format("DD-MM-YYYY");

      return `${START} até ${END}`;
    },

    getMonthUntilToday() {
      const START_MONTH_DATE = moment().startOf("month").format("DD-MM-YYYY");
      const ACTUAL_DATE = moment().format("DD-MM-YYYY");
      const S = `${START_MONTH_DATE} até ${ACTUAL_DATE}`;
      return S;
    },

    getLastMonth() {
      const START_LAST_MONTH_DATE = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("DD-MM-YYYY");

      const END_LAST_MONTH_DATE = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("DD-MM-YYYY");

      return `${START_LAST_MONTH_DATE} até ${END_LAST_MONTH_DATE}`;
    },

    async callAllMethodsFromReport() {
      this.isLoading = true;

      // garantir que serão chamados primeiro os métodos para a montagem da jornada
      await this.getAudienceList(),
        await this.getTemplatesList(),
        await Promise.all([
          // this.getTemplatesAndAudiencesList(),
          // this.getAudienceList(),
          // this.getTemplatesList(),

          //   this.getTotalSent(),
          this.getTotalEmailSent(),

          //  this.getTotalSMSSent(),
          this.getTotalBounced(),
          this.getTotalOptOuts(),
          this.getTotalSpamed(),
          this.getTotalOpens(),
          // this.getTotalClicks(),
          this.getTotalEmailClicks(),
          this.getTotalSMSClicks(),
          this.getTotalByChannel(),
          this.getAllJourneys(),

          // this.getOpenRateByDateRange(),
          // this.getClickRateByDateRange(),
          this.getSentQuantity(),
          this.getEngageQuantity(),
          this.getJourneysReportsByCompanyId(),
          this.getCurrentJourney(),
          this.getUsersList(),
          this.getJourneyBounceAndClickRateChartData(),
          //  this.getJourneyAudienceUniqueIds()
          // this.getTemplateReportsByCompanyId(),
        ]);
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },

    async filterReports() {
      if (this.selectedInterval !== 6) await this.callAllMethodsFromReport();
      else if (this.$refs.form.validate() && this.pickerRange)
        await this.callAllMethodsFromReport();
    },

    formatDate(date) {
      const DATE = moment.utc(date).format("DD/MM/YYYY HH:mm");
      return DATE == "Invalid date" || DATE == "Data inválida" ? "Sem informação" : DATE;
    },
    formatDate2(date) {
      const DATE = moment(date).format("DD/MM/YYYY");
      return DATE == "Invalid date" || DATE == "Data inválida" ? "Sem informação" : DATE;
    },

    formatHour(date) {
      return moment(date).format("HH:mm");
    },

    // async getTotalSent() {
    //   this.http
    //     .post("reports/getTotalSent", this.filter)
    //     .then((resp) => {
    //       this.totalSent = resp.total;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async getAudienceList() {
      try {
        const res = await this.http.get("audience/list");
        this.audiencesList = res;
        return;
      } catch (error) {
        console.log(error);
      }

      return;
    },

    async getUsersList() {
      try {
        const res = await this.http.post("reports/getSomeUserjouneysByJourney", {
          journeyId: this.$route.params.id,
          ...this.filter,
        });
        this.usersList = res;
        return;
      } catch (error) {
        console.log(error);
      }

      return;
    },
    async getTemplatesList() {
      try {
        const resp = await this.http.get("templatesConfiguration/list");

        this.templatesSMSList = resp.filter((d) => d.templateType === "sms");
        this.templatesEmailList = resp.filter((d) => d.templateType === "email");
        return;
      } catch (err) {
        console.log(err);
      }

      return;
    },
    async getTotalEmailSent() {
      let filterLocal = { ...this.filter };
      filterLocal.channel = "email";
      this.http
        .post("reports/getTotalSent", { ...filterLocal })
        .then((resp) => {
          this.totalEmailSent = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getJourneyBounceAndClickRateChartData() {
      let filterLocal = { ...this.filter };
      filterLocal.journeyId = this.$route.params.id;

      try {
        this.bouncesAndClicksChartDataLoading = true;
        const resp = await this.http.post(
          "reports/getJourneyBounceAndClickRateChartData",
          { ...filterLocal }
        );

        this.bouncesAndClicksChartDataInformation = await this.mountBouncesAndClicksGraphObject(
          resp
        );
        this.bouncesAndClicksChartDataLoading = false;
      } catch (error) {
        console.error(error);
        this.bouncesAndClicksChartDataLoading = false;
      }
    },

    async getJourneyAudienceUniqueIds() {
      this.http
        .post("reports/getJourneyAudienceUniqueIds", {
          audienceId: this.currentJourney.journey.audienceId,
        })
        .then((resp) => {
          this.audienceUniqueIdsCounter = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllJourneys() {
      this.http
        .post("reports/getAllJourneys", this.filter)
        .then((resp) => {
          this.journeys = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // async getTotalSMSSent() {
    //   let filterLocal = { ...this.filter };
    //   filterLocal.channel = "sms";
    //   this.http
    //     .post("reports/getTotalSent", { ...filterLocal })
    //     .then((resp) => {
    //       this.totalSMSSent = resp.total;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    async getTotalOptOuts() {
      let filterLocal = { ...this.filter };
      filterLocal.channel = "email";
      this.http
        .post("reports/getTotalOptOuts", filterLocal)
        .then((resp) => {
          this.totalOptOuts = resp.total;
        })
        .catch((err) => {
          console.log(err);
          this.totalOptOuts = 0;
        });
    },

    async getTotalOpens() {
      this.http
        .post("reports/getTotalOpens", this.filter)
        .then((resp) => {
          this.totalOpens = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getTotalBounced() {
      let filterLocal = { ...this.filter };
      filterLocal.channel = "email";
      this.http
        .post("reports/getTotalBounced", filterLocal)
        .then((resp) => {
          this.totalBounced = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getTotalSpamed() {
      let filterLocal = { ...this.filter };
      filterLocal.channel = "email";
      this.http
        .post("reports/getTotalSpamed", filterLocal)
        .then((resp) => {
          this.totalSpamed = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // async getTotalClicks() {
    //   this.http
    //     .post("reports/getTotalClicks", this.filter)
    //     .then((resp) => {
    //       this.totalClicks = resp.total;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async getTotalEmailClicks() {
      let filterLocal = { ...this.filter };
      filterLocal.channel = "email";
      this.http
        .post("reports/getTotalClicks", filterLocal)
        .then((resp) => {
          this.totalEmailClicks = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getTotalSMSClicks() {
      let filterLocal = { ...this.filter };
      filterLocal.channel = "sms";
      this.http
        .post("reports/getTotalClicks", filterLocal)
        .then((resp) => {
          this.totalSMSClicks = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getTotalByChannel() {
      // this.http
      //   .post("reports/getTotalByChannel", this.filter)
      //   .then((resp) => {
      //     this.totalByChannel = resp;

      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      try {
        const response = await this.http.post("reports/getTotalByChannel", this.filter);
        this.totalByChannel = response;
        this.donutChartData = await this.mountDonutGraphObject(response);
      } catch (error) {
        console.log(error);
      }
    },

    async getSentQuantity() {
      const FILTER = {
        startDate: this.filter.startsAt,
        endDate: this.filter.endsAt,
        groupBy: this.filter.groupBy,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        stepId: this.filter?.stepId,
      };
      try {
        const response = await this.http.post("reports/getSentQuantityChartData", FILTER);
        this.sendChartDataInformationArr = [];

        // const mock = {
        //   sent: [1, 2, 3, 4, 5, 6, 7,8 , 9, 10],
        //   opened: [4, 5, 6, 7, 8, 9, 0, 12, 24, 45],
        //   clicked: [54, 34, 5, 6, 3, 5,6, 8, 9,34],
        // };

        this.sendChartDataInformation = await this.mountSentGraphObject(response);

        this.sendChartDataInformationArr.push(this.sendChartDataInformation);
      } catch (error) {
        console.error(error);
      }
    },

    async getSentQuantityToChart(start, end, group) {
      const FILTER = {
        startDate: start,
        endDate: end,
        groupBy: group,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        stepId: this.filter?.stepId,
      };

      let INFO = null;
      try {
        const response = await this.http.post("reports/getSentQuantityChartData", FILTER);
        INFO = await this.mountSentGraphObject(response);
      } catch (error) {
        console.error(error);
      }

      return INFO;
    },

    async getEngageQuantity() {
      const FILTER = {
        startDate: this.filter.startsAt,
        endDate: this.filter.endsAt,
        groupBy: this.filter.groupBy,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        stepId: this.filter?.stepId,
      };
      try {
        const response = await this.http.post("reports/getRateChartData", FILTER);
        this.engageChartDataInformationArr = [];

        // const mock = {
        //   clickRate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        //   openRate: [4, 5, 6, 7, 8, 9, 0, 12, 24, 45],
        // };

        this.engageChartDataInformation = await this.mountEngageGraphObject(response);
        this.engageChartDataInformationArr.push(this.engageChartDataInformation);
      } catch (error) {
        console.error(error);
      }
    },

    async getEngageQuantityToChart(start, end, group) {
      const FILTER = {
        startDate: start,
        endDate: end,
        groupBy: group,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        stepId: this.filter?.stepId,
      };
      let INFO = null;

      try {
        const response = await this.http.post("reports/getRateChartData", FILTER);
        INFO = await this.mountEngageGraphObject(response);
      } catch (error) {
        console.error(error);
      }

      return INFO;
    },

    // async getOpenRateByDateRange() {
    //   this.http
    //     .post("reports/getOpenRateByDateRange", this.filter)
    //     .then((resp) => {
    //       this.openRateByDateRange = resp;
    //       this.opensData = this.treatDataRange(resp);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // async getClickRateByDateRange() {
    //   this.http
    //     .post("reports/getClickRateByDateRange", this.filter)
    //     .then((resp) => {
    //       this.clickRateByDateRange = resp;
    //       this.clicksData = this.treatDataRange(resp);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    async getJourneysReportsByCompanyId() {
      const URL = this.currentTableUrl
        ? this.currentTableUrl
        : "reports/getJourneysReportsByCompanyId";

      this.http
        .post(URL, this.filter)
        .then((resp) => {
          let temp = [...resp];
          this.journeys = temp;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getTemplatesAndAudiencesList() {
      const PROMISES = [
        this.http.get("templatesConfiguration/list"),
        this.http.get("audience/list"),
      ];

      await Promise.all(PROMISES)
        .then((results) => {
          const [TEMPLATES, AUDIENCES] = results;

          this.templatesSMSList = TEMPLATES.filter((d) => d.templateType === "sms");
          this.templatesEmailList = TEMPLATES.filter((d) => d.templateType === "email");
          this.audiencesList = AUDIENCES;

          return;
        })
        .catch(() => {
          this.$toast.error("Error ao buscar templates e audiências!");
        });

      return;
    },
    buildTree(arr) {
      const nodeMap = {};
      let tree = {};

      const BLOCK_TYPES = {
        selectAudience: {
          label: "Selecionar Audiência",
          isDataSelected: false,
          formatType: "circle",
          hasNextNode: true,
          color: "#dcdcdc",
          icon: "mdi-calendar",
          lastNode: null,
        },
        final: {
          label: "Final",
          isDataSelected: false,
          formatType: "circle",
          hasNextNode: false,
          color: "#dcdcdc",
          icon: "$exit_to_app_rounded",
          nextNode: null,
          lastNode: null,
          fromClick: "next",
        },
        sendEmail: {
          label: "Selecionar\ne-mail",
          formatType: "square",
          hasNextNode: true,
          isDataSelected: false,
          icon: "mdi-email",
          color: "#0b99ff",
          lastNode: null,
          nextNode: null,
          fromClick: "next",
        },
        sendSMS: {
          label: "Enviar SMS",
          formatType: "square",
          hasNextNode: true,
          icon: "mdi-message-processing",
          color: "#0b99ff",
          isDataSelected: false,
          lastNode: null,
          nextNode: null,
          fromClick: "next",
        },
        await: {
          label: "Selecionar período",
          type: "await",
          icon: " $timer_rounded",
          formatType: "circle",
          hasNextNode: true,
          isDataSelected: false,
          color: "#dcdcdc",
          lastNode: null,
          nextNode: null,
          fromClick: "next",
        },
        selectAudienceFilter: {
          label: "Selecionar audiência filtrada",
          type: "selectAudienceFilter",
          formatType: "diamond",
          hasNextNode: true,
          isDataSelected: false,
          color: "#dcdcdc",
          lastNode: null,
          YesNext: null,
          NoNext: null,
          fromClick: "next",
        },
        selectTestABFilter: {
          label: "Teste A/B",
          type: "selectTestABFilter",
          formatType: "diamond",
          hasNextNode: true,
          color: "#dcdcdc",
          isDataSelected: false,
          lastNode: null,
          YesNext: null,
          NoNext: null,
          fromClick: "next",
        },
      };

      arr.forEach((obj) => (nodeMap[obj.id] = obj));

      arr.forEach((obj) => {
        const PARENT_ID = obj.lastStepId;
        const DATA = obj;

        // MERGE DE OBJETOS, PARA O OBJETO TER TODAS A PROPRIEDADES
        const NODE = Object.assign(DATA, BLOCK_TYPES[obj.type]);

        // VERIFICAR CADA TIPO DE INFORMAÇÃO PARA VERIFICAR SE JÁ TEM DADOS SELECIONADOS OU NÃO
        if (NODE.type === "selectAudience")
          if (this.currentJourney.journey.audienceId) {
            NODE.isDataSelected = true;
            const AUDIENCE = this.audiencesList.find(
              (d) => d.id === this.currentJourney.journey.audienceId
            );
            if (AUDIENCE) NODE.label = AUDIENCE.name;
          }

        if (NODE.type === "sendSMS" || NODE.type === "sendEmail")
          if (NODE.templateId) {
            NODE.isDataSelected = true;
            const TEMPLATE =
              NODE.type === "sendSMS"
                ? this.templatesSMSList.find((d) => d.id == NODE.templateId)
                : this.templatesEmailList.find((d) => d.id == NODE.templateId);
            if (TEMPLATE) NODE.label = TEMPLATE.templateName;
          }

        if (NODE.type === "await")
          if (NODE.wait && NODE.waitValue) {
            NODE.isDataSelected = true;
            NODE.label = `${NODE.waitValue} ${NODE.wait}`;
          }

        // SE NÃO TIVER O PARENTE ID, ELE É O PRIMEIRO NÓ (RAIZ)
        if (!PARENT_ID) {
          tree = NODE;
          // this.pushNewNode(this.data.nodes.length - 1, NODE, true);
        } else {
          const PARENT_NODE = nodeMap[PARENT_ID];

          // SE O YESNEXTID FOR NULO, O PROXIMO NÓ OBRIGATORIAMENTE É DO NEXTNODE
          if (!PARENT_NODE.yesNextStepId) {
            PARENT_NODE.nextNode = {};
            PARENT_NODE.nextNode = NODE;
          }

          if (PARENT_NODE.yesNextStepId) {
            PARENT_NODE.YesNext = {};
            PARENT_NODE.YesNext = nodeMap[PARENT_NODE.yesNextStepId];
            PARENT_NODE.YesNext.fromClick = "yes";

            if (PARENT_NODE.nextStepId) {
              PARENT_NODE.nextNode = {};
              PARENT_NODE.nextNode = nodeMap[PARENT_NODE.nextStepId];
            }
          }
          //  this.pushNewNode(this.data.nodes.length - 1, NODE, true);
        }
      });

      return tree;
    },
    async getCurrentJourney() {
      try {
        this.isTreeLoading = true;
        const response = await this.http.get(`journey/findOne/${this.$route.params.id}`);
        this.currentJourney = response;
        // chamo aqui pois no primeiro carregamento, eu preciso obrigatóriamente da minha audiencia atual, para mandar o id no body do método abaixo
        this.getJourneyAudienceUniqueIds();
        this.journey = response.journey;
        const TREE = await this.buildTree(response.steps);
        this.tree = TREE;
        this.keyComponent++;
        this.databaseConfigId = this.journey.audienceId;
      } catch (error) {
        console.log(error);
        this.$toast.error("Erro ao buscar informações");
        this.isTreeLoading = false;
      }

      return;
    },

    async getTemplateReportsByCompanyId() {
      // const URL = this.currentTableUrl
      //   ? this.currentTableUrl
      //   : "reports/getJourneysReportsByCompanyId";
      // this.http
      //   .post(URL, this.filter)
      //   .then((resp) => {
      //     this.journeys = resp;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },

    orderByModal() {
      this.getSortedJourneysReportsByCompanyId(
        this.tableName.value,
        this.orderName.value
      );

      this.closeOnContentClick = true;
      setTimeout(() => {
        this.closeOnContentClick = false;
      }, 300);
    },

    async getSortedJourneysReportsByCompanyId(name, order) {
      let ORDER = null;

      if (order != null) ORDER = order;
      else {
        this.sortDesc = !this.sortDesc;
        ORDER = this.sortDesc ? "ASC" : "DESC";
      }
      const URL = `reports/getJourneysReportsByCompanyId?sort=${name}&order=${ORDER}`;
      this.currentTableUrl = URL;

      try {
        this.loadingSortTable = true;
        const resp = await this.http.post(URL, this.filter);
        this.journeys = resp;
        this.loadingSortTable = false;
      } catch (error) {
        this.$toast.error("Erro ao realizar ordenação");
        console.error(error);
        this.loadingSortTable = false;
      }
    },

    async chooseHowMountLabel(start, end, infoLength) {
      let LABELS = [];
      if (this.selectedInterval >= 4 || this.isFilteringGraph) {
        if (this.isFilteringHour) {
          LABELS = await this.mountLabelsHour();
        } else if (infoLength > 1 && this.filter.groupBy == "day") {
          LABELS = await this.mountLabelsDays(start, end);
        } else LABELS = await this.mountLabelsMonth(start, end);
      }

      this.selectedInterval < 4 && !this.isFilteringGraph && !this.isFilteringHour
        ? (LABELS = await this.mountLabelsMonth(start, end))
        : "";

      return LABELS || [];
    },
    async mountBouncesAndClicksGraphObject(info) {
      let chartData = {};
      let DATASETS = [
        { data: [], label: "Bounces", backgroundColor: "#00BBD3" },
        { data: [], label: "Cliques", backgroundColor: "#B2D2D6" },
      ];
      const LABELS = [];
      const KEYS = Object.keys(info);

      for (let i = 0; i < KEYS.length; i++) {
        const KEY = KEYS[i];
        LABELS.push(info[KEY].stepNameForTemplate);
        DATASETS[0].data.push(info[KEY].bounceRate);
        DATASETS[1].data.push(info[KEY].clickRate);
      }

      chartData.labels = LABELS;
      chartData.datasets = DATASETS;

      return chartData;
    },
    async mountEngageGraphObject(info) {
      moment.locale("pt-br");
      let chartData = {};
      let datasets = [];
      const START = moment(this.filter.startsAt);
      const END = moment(this.filter.endsAt);
      const LENGTH = info.clickRate.length;
      const LABELS = await this.chooseHowMountLabel(START, END, LENGTH);

      datasets[1] = {
        data: info.clickRate,
        label: "Clicados",
        backgroundColor: "#00BBD3",
      };

      datasets[0] = { data: info.openRate, label: "Abertos", backgroundColor: "#B2D2D6" };

      chartData.labels = LABELS;
      chartData.datasets = datasets;

      chartData;
      return chartData;
    },

    async mountSentGraphObject(info) {
      moment.locale("pt-br");
      let chartData = {};
      let datasets = [];
      const START = moment(this.filter.startsAt);
      const END = moment(this.filter.endsAt);
      const LENGTH = info.clicked.length;
      const LABELS = await this.chooseHowMountLabel(START, END, LENGTH);

      datasets[0] = { data: info.opened, label: "Abertos", backgroundColor: "#B2D2D6" };
      datasets[1] = { data: info.clicked, label: "Clicados", backgroundColor: "#00BBD3" };
      datasets[2] = { data: info.sent, label: "Enviados", backgroundColor: "#004C91" };

      chartData.labels = LABELS;
      chartData.datasets = datasets;

      chartData;
      return chartData;
    },

    async mountDonutGraphObject(info) {
      const chart_data = {};
      if (info) {
        let LABELS = Object.keys(info);
        const DATAS = Object.values(info);

        const DATASETS = [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: DATAS,
          },
        ];

        chart_data.labels = LABELS;
        chart_data.datasets = DATASETS;
      }

      return chart_data;
    },

    // treatDataRange(arr) {
    //   let temp = [];
    //   for (let index = 0; index < 12; index++) {
    //     const i = arr.findIndex((item) => parseInt(item.month) === index + 1);
    //     if (i === -1) {
    //       temp.push(0);
    //     } else {
    //       temp.push(parseInt(arr[i].count));
    //     }
    //   }
    //   return temp;
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.tree-component {
  height: 380px;
  // border: 1px solid red;

  padding: $z-s-4 $z-s-2 $z-s-1;
  overflow-y: auto;
  @include trackScrollBar;
  display: flex;
  // align-items: center;
  // padding: 0px !important;
  // padding: 0 10px !important;
}
.icon_card_report {
  height: 50px;
  width: 50px;
  background-color: rgb(246, 189, 2);
  border-radius: 30px;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;

    // overflow-y: auto;
    // @include trackScrollBar;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;

      .left {
        flex: 1 1 auto;

        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }

      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;

          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;

      // overflow-y: auto;
      // @include trackScrollBar;
      .table-wrapper {
        height: 450px !important;
        max-height: 500px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
          height: 250px;
        }

        @media (min-width: 1400px) {
          height: auto !important;
          max-height: 650px;
        }
      }

      // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              width: 33px;
              max-width: 33px;
              &:not(:first-child) {
                text-align: center !important;
              }

              &:last-child {
                text-align: right !important;
              }
            }

            td {
              width: 33px;
              max-width: 33px;
              &:not(:first-child) {
                text-align: center !important;

                & > div,
                span {
                  margin: 0 auto !important;
                }
              }

              &:last-child {
                text-align: right !important;
              }

              & > div,
              span {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }

  .badge {
    padding: 0.03rem 0.25rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }

    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;

  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;

    span {
      font-weight: 500;
    }

    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;

  &__title {
    text-align: center;
    font-weight: 500;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
