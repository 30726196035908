var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "padding-top": "1rem" } }, [
    _c(
      "div",
      { staticClass: "reports_filter px-5 mb-8" },
      [
        _c(
          "v-form",
          { ref: "form", staticClass: "d-flex align-center" },
          [
            !_vm.isLoading
              ? _c("span", [
                  _c("small", [_vm._v("Jornada: ")]),
                  !_vm.isLoading
                    ? _c("strong", [
                        _vm._v(_vm._s(_vm.currentJourney?.journey?.name || "")),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "v-row",
              { attrs: { dense: "", justify: "end" } },
              [
                _vm.selectedInterval === 6
                  ? _c(
                      "v-col",
                      { attrs: { lg: "3", md: "5", sm: "12", xs: "12" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-center",
                            staticStyle: { height: "40px" },
                          },
                          [
                            _c("DatePicker", {
                              staticStyle: { width: "100% !important" },
                              attrs: {
                                range: "",
                                "value-type": "YYYY-MM-DD",
                                format: "DD/MM/YYYY",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.setValuePickerToFilter($event)
                                },
                              },
                              model: {
                                value: _vm.pickerRange,
                                callback: function ($$v) {
                                  _vm.pickerRange = $$v
                                },
                                expression: "pickerRange",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  { attrs: { cols: _vm.selectedInterval < 6 ? 3 : 3 } },
                  [
                    _c("z-select", {
                      staticClass: "intervalo_relatorio",
                      staticStyle: { "max-height": "240px !important" },
                      attrs: {
                        hideDetails: true,
                        label: "Selecione o intervalo",
                        itemValue: "value",
                        items: _vm.intervalFilters,
                        itemText: "name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "section",
                                {
                                  staticClass:
                                    "d-flex align-center justify-space-between",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                  ]),
                                  _c("small", [
                                    _vm._v(" " + _vm._s(item.textDate) + " "),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedInterval,
                        callback: function ($$v) {
                          _vm.selectedInterval = $$v
                        },
                        expression: "selectedInterval",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.isLoading
      ? _c("div", { staticClass: "wrapper__card mx-5" }, [
          _c(
            "div",
            { staticClass: "wrapper__card__body" },
            [
              _c("v-row", { staticClass: "pl-3" }, [
                _c(
                  "h2",
                  { staticStyle: { "font-size": "1.25rem !important" } },
                  [_vm._v(" Métricas disponíveis apenas para E-mail ")]
                ),
              ]),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "space-around" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: {
                                  "background-color": "rgb(246, 189, 2)",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$send")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("ENVIADOS")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgb(246, 189, 2)",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.totalEmailSent) + " ")]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: {
                                  "background-color": "rgb(105, 188, 203)",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("mdi-email-open-outline")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("ABERTURAS")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgb(105, 188, 203)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.totalOpens) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalEmailSent !== 0
                                              ? (
                                                  (_vm.totalOpens /
                                                    _vm.totalEmailSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%) "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: {
                                  "background-color": "rgba(73, 203, 140, 1)",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$arrow_back")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("TOTAL CLIQUES")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgba(73, 203, 140, 1)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.totalEmailClicks) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalEmailSent !== 0
                                              ? (
                                                  (_vm.totalEmailClicks /
                                                    _vm.totalEmailSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%)"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: {
                                  "background-color": "rgb(100, 45, 108)",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("mdi-email-sync-outline")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("BOUNCES")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgb(100, 45, 108)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.totalBounced) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalEmailSent !== 0
                                              ? (
                                                  (_vm.totalBounced /
                                                    _vm.totalEmailSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%)"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: { "background-color": "#ffd2a9" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("mdi-alert-octagon-outline")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("SPAM")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#ffd2a9" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.totalSpamed) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalEmailSent !== 0
                                              ? (
                                                  (_vm.totalSpamed /
                                                    _vm.totalEmailSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%)"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: { "background-color": "#e7a2cc" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$unsubscribe")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("OPTOUT")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#e7a2cc" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.totalOptOuts) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalEmailSent !== 0
                                              ? (
                                                  (_vm.totalOptOuts /
                                                    _vm.totalEmailSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%)"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: { "background-color": "#004c91" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$reply")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("REPLYS")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#004c91" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.totalSpamed) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalEmailSent !== 0
                                              ? (
                                                  (_vm.totalSpamed /
                                                    _vm.totalEmailSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%)"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: {
                                  "background-color": "rgb(222, 87, 66)",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$groups")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("AUDIÊNCIAS")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgb(222, 87, 66)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.audienceUniqueIdsCounter)
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-6", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("JORNADA")]
                              ),
                              _c(
                                "span",
                                [
                                  _c("small", { staticClass: "mr-2" }, [
                                    _vm._v("Início: "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.startDateComputed || "s.d")
                                      ),
                                    ]),
                                  ]),
                                  _c("small", [
                                    _vm._v("Fim: "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.endDateComputed || "s.d")
                                      ),
                                    ]),
                                  ]),
                                  _vm.clickedsNodes.length
                                    ? [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-5",
                                            attrs: { color: "#004C91" },
                                            on: {
                                              click: _vm.resetFilterByJourney,
                                            },
                                          },
                                          [_vm._v("$settings_backup_restore")]
                                        ),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v("|"),
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { color: "#004C91" },
                                          },
                                          [_vm._v("$filter_alt")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "tree-component" },
                            [
                              _c("TreeComponent", {
                                key: _vm.keyComponent,
                                attrs: {
                                  "database-config-id-prop":
                                    _vm.databaseConfigId,
                                  nodes: _vm.tree,
                                },
                                on: { node_click: _vm.treeNodeClicked },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("BOUNCES/CLIQUE")]
                              ),
                            ]
                          ),
                          _c(
                            "section",
                            [
                              !_vm.bouncesAndClicksChartDataLoading
                                ? _c("BarChart", {
                                    attrs: {
                                      "chart-data":
                                        _vm.bouncesAndClicksChartDataInformation,
                                      "chart-options":
                                        _vm.bouncesAndClicksChartOptions,
                                    },
                                  })
                                : _c(
                                    "aside",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center",
                                      staticStyle: { height: "320px" },
                                    },
                                    [_c("z-loading")],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("CANAL")]
                              ),
                              _vm.filter.channel
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "#004C91" },
                                      on: { click: _vm.doughnutChartCancel },
                                    },
                                    [_vm._v("$settings_backup_restore")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.totalByChannel
                            ? _c("DoughnutChart", {
                                attrs: {
                                  labels: ["Email", "SMS"],
                                  totals: _vm.totalByChannel,
                                  "chart-info-prop": _vm.donutChartData,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-5 py-4 d-flex justify-end align-center",
                            },
                            [
                              _c("z-btn", {
                                attrs: {
                                  small: "",
                                  rounded: "",
                                  primary: "",
                                  text: "",
                                  icon: `mdi-cloud-download`,
                                  "is-loading": _vm.loadingDownload,
                                },
                                on: { click: _vm.downloadDocument },
                              }),
                            ],
                            1
                          ),
                          _c("z-pagination", {
                            attrs: {
                              "auto-pagination": "",
                              "icon-previous": "mdi-chevron-left",
                              "icon-next": "mdi-chevron-right",
                              "items-per-page": 10,
                              "number-of-items": _vm.usersList.length,
                              items: _vm.usersList,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "data",
                                  fn: function ({ paginatedItems }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "table-wrapper",
                                          staticStyle: {
                                            height: "550px !important",
                                            "max-height": "550px !important",
                                          },
                                        },
                                        [
                                          _c("z-table", {
                                            staticClass: "table",
                                            attrs: {
                                              headers: _vm.headers,
                                              items: paginatedItems,
                                              "icon-next": "mdi-chevron-right",
                                              "icon-previous":
                                                "mdi-chevron-left",
                                              "is-loading":
                                                _vm.loadingSortTable,
                                            },
                                            on: {
                                              sort: function ($event) {
                                                return _vm.getSortedJourneysReportsByCompanyId(
                                                  $event
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "no-data",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-data-table d-flex align-end justify-center",
                                                        },
                                                        [
                                                          _c("h3", [
                                                            _vm._v(
                                                              "Sem dados disponíveis."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: `item.created`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  item.created
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: `item.journeyStepId`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getStepIdName(
                                                                  item.journeyStepId
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1234437936
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "wrapper__card" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [_c("z-loading")],
                1
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }